<template>
    <div class="wrap">
        <div class="flex justify-start" style="padding: 20px">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="选择活动">
                    <el-select v-model="searchForm.activity_id" placeholder="请选择" filterable>
                        <el-option v-for="(item, index) in activityList" :label="item.name" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单号">
                    <el-input v-model="searchForm.order_no"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search()">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button plain @click="reset()">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="orderData" v-loading="loading">
            <el-table-column type="index" width="50" />
            <el-table-column prop="order_no" label="订单号" />
            <el-table-column prop="activity_name" label="活动名" />
            <el-table-column prop="num" label="购买数量" align="center" />
            <el-table-column prop="pay_money" label="支付金额" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.pay_money}` }}
                </template>
            </el-table-column>
            <el-table-column prop="pay_status" label="支付状态" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" plain v-if="scope.row.pay_status === 1">待支付</el-button>
                    <el-button type="primary" size="mini" plain v-else-if="scope.row.pay_status === 2">已支付</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="one_fenxiao_name" label="业务员" align="center" />
            <el-table-column prop="one_fenxiao_commission" label="业务员佣金" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.one_fenxiao_commission}` }}
                </template>
            </el-table-column>
            <el-table-column prop="two_fenxiao_name" label="代理商" align="center" />
            <el-table-column prop="two_fenxiao_commission" label="代理商佣金" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.two_fenxiao_commission}` }}
                </template>
            </el-table-column>
            <el-table-column prop="yunwei_name" label="运维人员" align="center" />
            <el-table-column prop="yunwei_commission" label="运维人员佣金" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.two_fenxiao_commission}` }}
                </template>
            </el-table-column>
            <el-table-column prop="commission" label="总佣金" align="center">
                <template slot-scope="scope">
                    {{ `￥${scope.row.commission}` }}
                </template>
            </el-table-column>
        </el-table>
        <div class="text-center margin-top">
            <el-pagination
                @current-change="search"
                :current-page="searchForm.page"
                :page-size="searchForm.page_size"
                layout="prev, pager, next, total, jumper"
                :total="searchForm.total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            orderData: [],
            loading: false,
            searchForm: {
                activity_id: Number(this.$route.params.id) || '',
                order_no: '',
                page: 1,
                page_size: 1,
                total: 0,
            },
            activityList: [],
        };
    },
    created() {
        this.$api.platformActivity.getActivityList({ page: 1, page_size: 999 }).then((res) => {
            this.activityList = res.data.data;
        });
        this.search(1);
    },
    methods: {
        search: function(page) {
            this.searchForm.page = page;
            this.loading = true;
            let searchForm = this.$util.emptyToUndefined(this.searchForm);
            this.$api.platformActivity.getMerchantOrderList(searchForm).then((res) => {
                this.orderData = res.data.data;
                this.searchForm.total = res.data.total;
                this.searchForm.page_size = res.data.per_page;
                this.loading = false;
            });
        },
        reset: function() {
            this.searchForm.order_no = '';
            this.search(1);
        },
    },
};
</script>
<style lang="scss" scoped></style>
